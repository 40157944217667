.popup {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    
    display: flex;
    align-items: center;
    justify-content: center;

    &__container {
        background-color: #FFF;
        padding: 20px;
        border-radius: 10px;
        max-width: 1140px;

        &-row {
            margin-top: 1em;
            display: flex;
            justify-content: center;
            gap: 10px;
        }

        &-text {
            word-wrap: break-word;
        }

        &-title {
            font-weight: 500;
        }
    }
}