.rc-tree .rc-tree-treenode span.rc-tree-checkbox {
    display: none;
}

.tree {

    &__buttons {
        display: flex;
        align-items: center;
        gap: 1em;
        margin-bottom: 1em;

        &-row {
            display: flex;
            gap: 0px;

            .btn {
                margin-left: -5px;
            }
        }
    }

    &__container {
        border: 1px solid #ddd;
        border-radius: 5px;
        padding: 0.5em;
        height: 70vh;
        overflow: auto;
    }
}