.create-user {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
    row-gap: 1em;

    .tree__container {
        height: 50vh;
    }

    .btn {
        width: fit-content;
    }
}

.container {
    .select-search-container {
        width: 100%;

        .select-search-input {
            background: #FFF;
            color: #000;

            border: 1px solid #ddd;

            &::placeholder {
                color: #1e1e2e;
            }
        }

        .select-search-select {
            background: #FFF;
            border: 1px solid #ddd;

            .select-search-options, .select-search-option {
                color: #000;
                background: none;
            }

            .select-search-option:hover {
                background: #ddd;
            }
        }
    }

}

.token__create {
    display: flex;
    flex-direction: column;
    gap: 1em;
    align-items: flex-start;
}