.header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    padding: 10px;
    background-color: #343a40;
    z-index: 1;

    a {
        color: #fff;
    }

    &__list {
        align-items: center;
        display: flex;
        gap: 15px;
    }

    &__container {
        display: flex;
        max-width: 1140px;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        margin: auto;
    }

    .btn {
        padding: 0;
        color: #FFF;
        border: none;

        &:hover {
            background: none;
            border: none;
        }
    }
}