h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1 {
  font-size: 2.5em;
}

input {
  outline: none;
  border: none;
  background: none;
}

.container {
  padding: 70px 15px 20px;
  max-width: 1140px;
  margin: auto;
}

.form-field {

  input {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem
  }

  &__label {
    margin-bottom: 0.5rem;
  }

  &__error {
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #dc3545;
  }

  &.invalid {

    input {
      border-color: #dc3545;
    }
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  cursor: pointer;

  &:hover {
    background-color: #0069d9;
    border-color: #0062cc;
  }

  &-blue {
    background-color: #007bff;
    border-color: #007bff;
    color: #FFF;
  }

  &-green {
    color: #FFF;
    background-color: #28a745;
    border-color: #28a745;

    &:hover {
      color: #fff;
      background-color: #218838;
      border-color: #1e7e34;
    }
  }

  &-red {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;

    &:hover {
      color: #fff;
      background-color: #c82333;
      border-color: #bd2130;
    }
  }

  &-block {
    display: block;
    width: 100%;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
  }
}

.center-text {
  text-align: center;
}

.table {
  border: 1px solid #dee2e6;

  &__head {
    border-bottom: 1px solid #dee2e6;
    font-weight: 500;
  }

  &__row {
    display: flex;
    border-top: 1px solid #dee2e6;
  }

  &__cell {
    padding: 0.75rem;
    border-left: 1px solid #dee2e6;
    
    flex: 1;

    &:first-child {
      border-left: none;
    }

    &.w-50 {
      max-width: 50px;
    }

    &.w-100 {
      max-width: 100px;
    }

    &-icon {
      max-width: 22px;
      max-height: 22px;

      cursor: pointer;

      &:hover {
        filter: grayscale(50%);
      }
    }

    &-row {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
}

.block {
  margin: 0.5rem 0;
}