.login {

  &__container {
    max-width: 50%;
    margin: auto;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
  }
}